import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const BANK = 'bank'
export const CHEQUE = 'cheque'
export const PAYMENT = 'payment'
export const Financ = 'finance'

export function get_banks(page: number, pageSize: number) {
  return SecureCall.get(API_URL + BANK + `?page=${page}&limit=${pageSize}`)
}


export function get_cheques(data:any) {

  const params = new URLSearchParams()

  if (data?.page) {
    // params = params + `page=${page}`
    params.append('page', data?.page.toString())
  }
  if (data?.pageSize) {
    // params = params + `&limit=${pageSize}`
    params.append('limit', data?.pageSize.toString())
  }
  
  if (data?.fromDate) {
    // params = params + `&fromDate=${fromDate}`
    params.append('fromDate', data?.fromDate)
  }
  if (data?.toDate) {
    // params = params + `&toDate=${toDate}`
    params.append('toDate', data?.toDate)
  }

  if (typeof data?.chequeNumber == 'number') {
    params.append('number', data?.chequeNumber)
  }

  if (typeof data?.chequeType == 'number') {
    if(data?.chequeType == 1){
      params.append('$buyerUser.organization.id$', data?.orgId)
    }else{
      params.append('$sellerUser.organization.id$', data?.orgId)
    }
    
  }

  // return SecureCall.get(API_URL + CART + '?' + params)
  return SecureCall.get(API_URL + CHEQUE + '?' + params)

  // return SecureCall.get(API_URL + CHEQUE + `?page=${page}&limit=${pageSize}`)
}

export function set_cheque(data:any) {
  return SecureCall.post(API_URL + CHEQUE ,data)
}

export function update_cheque(id: number, data: any) {
  console.log("Datastatat : ",id)
  return SecureCall.update(API_URL + CHEQUE + `/${id}`, data)
}


export function get_payments(page: number, pageSize: number) {
  return SecureCall.get(API_URL + PAYMENT + `?page=${page}&limit=${pageSize}`)
}

export function set_payment(data:any) {
  return SecureCall.post(API_URL + PAYMENT ,data)
}


/////////////////////// financ /////////////////////////////
export function get_financs(data: any) {

  const params = new URLSearchParams()

  if (data?.page) {
    // params = params + `page=${page}`
    params.append('page', data?.page.toString())
  }
  if (data?.pageSize) {
    // params = params + `&limit=${pageSize}`
    params.append('limit', data?.pageSize.toString())
  }
  
  if (data?.fromDate) {
    // params = params + `&fromDate=${fromDate}`
    params.append('fromDate', data?.fromDate)
  }
  if (data?.toDate) {
    // params = params + `&toDate=${toDate}`
    params.append('toDate', data?.toDate)
  }

 

  return SecureCall.get(API_URL + Financ + '?' + params)
}


export function get_financ(id: any) {
  
  return SecureCall.get(API_URL + Financ + '/' + id)
}
