import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {Link, useHistory} from 'react-router-dom'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import {authState} from '../../../auth/redux/AuthSlice'
import {convertDate} from '../../../../functions/convertDate'
import {financState, getPayments, handle_variables} from '../../redux/financSlice'
import PaymentRegistrationModal from './PaymentRegistrationModal'
import {priceSlice} from '../../../../functions/priceSlice'

const Payment = () => {
  const dispatch = useDispatch()
  const state = useSelector(financState)
  const authS = useSelector(authState)
  const history = useHistory()

  const orgType = authS?.user?.organization?.organizationType?.id

  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getPayments(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        paymentPagination: {...state.paymentPagination, page: currentPage},
      })
    )
    dispatch(getPayments({page: currentPage, pageSize: state.paymentPagination.limit}))
  }

  return (
    <>
      {state.addPaymentModalOpen ? <PaymentRegistrationModal edit={editMode} /> : null}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row>
            <Button
              onClick={() => {
                dispatch(handle_variables({addPaymentModalOpen: true}))
                setEditMode(false)
              }}
              className='btn btn-primary'
            >
              ثبت درخواست نقد
            </Button>
          </Row>

          <Row className='w-100 mt-6'>
            <Col lg={2}>
              <h3 className='card-title align-items-center'>
                <span className='card-label fw-bolder fs-3 mb-1'>پرداخت های نقد</span>
              </h3>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {/* begin::Table container */}
          {state.paymentTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                {state.payments.length < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-100px'>شماره پرداخت</th>
                        <th className='min-w-140px'>مشتری</th>
                        <th className='min-w-140px'>مبلغ (تومان)</th>
                        <th className='min-w-140px'>تاریخ پرداخت</th>
                        <th className='min-w-140px'>شماره سفارش</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.payments.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.id}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.buyerUser?.firstName}
                                {''} {item?.buyerUser?.lastName}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: Number(item?.amount)})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {convertDate({date: item?.date, type: 1})}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {' '}
                                {item?.cartId}
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}

                {/* end::Table */}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.paymentPagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.paymentPagination.page}
                  total={state?.paymentPagination.total}
                />
                <Select
                  defaultValue={state.paymentPagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(
                      handle_variables({
                        paymentPagination: {...state.paymentPagination, limit: e},
                      })
                    )
                    dispatch(
                      getPayments({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                  ]}
                />
              </div>
            </>
          )}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default Payment
