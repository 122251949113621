import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {addSellRequest} from '../../../../typescript/interface'
import {useDispatch, useSelector} from 'react-redux'

import {useFormik} from 'formik'
import * as Yup from 'yup'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'
import {flowerState, getOrganizationFlower} from '../../../flower/redux/flowerSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {stringPrice} from '../../../../functions/stringPrice'
import {BeatLoader, GridLoader} from 'react-spinners'
import {toast} from 'react-toastify'
import {
  financState,
  getBanks,
  getCheques,
  handle_variables,
  setCheque,
} from '../../redux/financSlice'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'
import {accountState, getBuyers} from '../../../accounts/Redux/AccountSlice'
import type {RadioChangeEvent} from 'antd'
import {Radio} from 'antd'

var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str: any) {
    if (typeof str === 'string') {
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

const ChequeRegistrationModal = ({
  edit = false,
  defaultValue,
}: {
  edit?: boolean
  defaultValue?: any
}) => {
  const state = useSelector(financState)
  const accountS = useSelector(accountState)
  const coopS = useSelector(cooperationState)
  const authS = useSelector(authState)

  console.log("authS",authS)

  const dispatch = useDispatch()

  const [buyer, setBuyer] = useState([])
  const [buyersExist, setBuyersExist] = useState(false)
  const [farmer, setFarmer] = useState([])
  const [farmersExist, setFarmersExist] = useState(false)
  const [userTypeCheque, setUserTypeCheque] = useState(1)

  const Schema = Yup.object().shape({
    amount: Yup.string().required('مبلغ چک نمی نواند خالی باشد'),
    number: Yup.string().required('شماره چک نمی نواند خالی باشد'),
    bank: Yup.string().required('بانک چک نمی نواند خالی باشد'),
    buyer: Yup.string().required(' صاحب چک نمی نواند خالی باشد'),
    date: Yup.string().required('تاریخ چک نمی نواند خالی باشد'),
  })

  const userTypeOptions = [
    {label: 'غرفه', value: 1},
    {label: 'مزرعه', value: 2},
  ]

  const userTypeOnChange = ({target: {value}}: RadioChangeEvent) => {
    setUserTypeCheque(value)
  }

  const initialValues = {
    amount: '',
    number: '',
    bank: '',
    buyer: '',
    date: '',
  }

  useEffect(() => {
    //get banks
    dispatch(getBanks({page: 1, pageSize: 50}))
  }, [])

  useEffect(() => {
    if (userTypeCheque == 1) {
      //get buyers
      dispatch(getBuyers({page: 1, pageSize: 500}))
    } else {
      //get farmers
      dispatch(getCoopRequest({page: 1, pageSize: 500}))
    }
  }, [userTypeCheque])

  if (!buyersExist && accountS?.buyers?.length > 0) {
    const mappedArray = accountS?.buyers?.map((item: any) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
    setBuyer(mappedArray)
    setBuyersExist(true)
  }

  if (!farmersExist && coopS?.coops?.length > 0) {
    //map cooperation request
    const mappedArray = coopS?.coops
      ?.map((item: any) => {
        let farmerOrg = null

        // Find Farmer Organization
        if (authS?.user?.organization?.id == item?.fromOrganization?.id) {
          farmerOrg = item?.toOrganization
        } else {
          farmerOrg = item?.fromOrganization
        }

        //Check have cooperation
        if (item?.cooperationStatusId == 1) {
          return {
            label: farmerOrg?.title,
            value: farmerOrg?.users[0]?.id,
          }
        }
        return null
      })
      .filter((item: any) => item !== null)

    setFarmer(mappedArray)
    setFarmersExist(true)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      dispatch(handle_variables({chequeSetLoading: true}))

      let buyerUserId = 0
      let sellerUserId = 0

      if(userTypeCheque ==1 ){
        buyerUserId = authS?.user?.id
        sellerUserId = +values.buyer
      }else{
        buyerUserId = +values.buyer
        sellerUserId = authS?.user?.id
      }

      try {
        const data = {
          amount: values.amount,
          number: values.number,
          date: values.date,
          bankId: values.bank,
          buyerUserId,
          sellerUserId
        }

        await dispatch(setCheque(data))
        dispatch(handle_variables({addChequeModalOpen: false}))
        dispatch(
          getCheques({page: state.chequePagination.page, pageSize: state.chequePagination.limit})
        )
      } catch (err) {
        dispatch(handle_variables({chequeSetLoading: true}))
        throw err
      }
    },
  })

  return (
    <>
      <Modal
        size='lg'
        show={state?.addChequeModalOpen}
        onHide={() => dispatch(handle_variables({addChequeModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>ثبت چک</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <>
              <Row>
                <Radio.Group
                  options={userTypeOptions}
                  onChange={userTypeOnChange}
                  value={userTypeCheque}
                  optionType='button'
                />
              </Row>
              <Row>
                <Col md={6} className='mt-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>مبلغ چک</label>
                  <input
                    type='number'
                    // value={promissoryNotes[index].promissoryNoteValue}
                    style={{direction: 'rtl'}}
                    className={'form-control form-control-lg form-control-solid no-spinners'}
                    {...formik.getFieldProps(`amount`)}
                    placeholder='مبلغ چک'
                  />
                  <div style={{color: 'green'}}>
                    {stringPrice({
                      data: formik.values.amount,
                    })}
                  </div>
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.amount}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>

                <Col md={6} className='mt-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>شماره چک</label>
                  <input
                    type='number'
                    style={{direction: 'rtl'}}
                    className={'form-control form-control-lg form-control-solid no-spinners'}
                    {...formik.getFieldProps('number')}
                    placeholder='شماره چک'
                  />
                  {formik.touched.number && formik.errors.number && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.number}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6} className='mt-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>بانک</label>
                  <DefaultDropDown
                    name='bank'
                    onChange={(e: any) => {
                      formik.setFieldValue('bank', e.id)
                    }}
                    type='default'
                    placeholder='بانک'
                    options={state?.banks}
                  />
                  {formik.touched.bank && formik.errors.bank && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.bank}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={6} className='mt-4'>
                  {userTypeCheque == 1 ? (
                    <>
                      <label className='form-label fw-bolder text-dark fs-6'>صاحب چک</label>
                      <DefaultDropDown
                        name='buyer'
                        onChange={(e: any) => {
                          formik.setFieldValue('buyer', e.value)
                        }}
                        type='default'
                        placeholder='صاحب چک'
                        options={buyer}
                      />
                      {formik.touched.buyer && formik.errors.buyer && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='invalid-error-text' role='alert'>
                              {formik.errors.buyer}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <label className='form-label fw-bolder text-dark fs-6'>در وجه</label>
                      <DefaultDropDown
                        name='buyer'
                        onChange={(e: any) => {
                          formik.setFieldValue('buyer', e.value)
                        }}
                        type='default'
                        placeholder='در وجه'
                        options={farmer}
                      />
                      {formik.touched.buyer && formik.errors.buyer && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='invalid-error-text' role='alert'>
                              {formik.errors.buyer}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col md={6} className='mt-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>تاریخ چک</label>
                  <DefaultDatePicker
                    placeholder={'تاریخ چک'}
                    from={(e: string) => {
                      formik.setFieldValue('date', e)
                    }}
                    position={'bottom'}
                    type='single'
                    minDate={true}
                  />
                  {formik.touched.date && formik.errors.date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.date}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({addChequeModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <Button type={state?.chequeSetLoading ? 'button' : 'submit'} variant='primary'>
              {state?.chequeSetLoading ? <BeatLoader size={3} color='white' /> : ' ثبت'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ChequeRegistrationModal
