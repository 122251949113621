import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {
  get_banks,
  get_cheques,
  get_financs,
  get_payments,
  set_cheque,
  set_payment,
  update_cheque,
} from './financApi'

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  banks: [],

  cheques: [],
  chequeTableLoading: false,
  addChequeModalOpen: false,
  chequeSetLoading: false,
  chequeUpdatedefaultValue: {},
  chequeUpdatedeLoading: false,
  chequePagination: {
    page: 1,
    limit: 10,
  },

  payments: [],
  paymentTableLoading: false,
  addPaymentModalOpen: false,
  paymentSetLoading: false,
  paymentUpdatedefaultValue: {},
  paymentUpdatedeDataLoading: false,
  paymentPagination: {
    page: 1,
    limit: 10,
  },

  financs: [],
  financsTableLoading: false,
  financUpdatedefaultValue: {},
  financUpdatedeDataLoading: false,
  financPagination: {
    page: 1,
    limit: 10,
  },
}

///////////////////////// BANKS   ////////////////////////////
export const getBanks = createAsyncThunk('financ/getBanks', async (data: any) => {
  try {
    const response = await get_banks(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

///////////////////////// CHEQUE   ////////////////////////////
export const getCheques = createAsyncThunk('financ/getCheques', async (data: any) => {
  try {
    const response = await get_cheques(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const setCheque = createAsyncThunk('financ/setCheque', async (data: any) => {
  try {
    const response = await set_cheque(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    console.log('errr', err)
    Promise.reject(err)
    throw err
  }
})

export const updateCheque = createAsyncThunk('financ/updateCheque', async (data : any) => {
  try {
    console.log("Datastatat : ",data)
    const response = await update_cheque(data?.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

///////////////////////// PAYMENT   ////////////////////////////
export const getPayments = createAsyncThunk('financ/getPayments', async (data: any) => {
  try {
    const response = await get_payments(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const setPayment = createAsyncThunk('financ/setPayment', async (data: any) => {
  try {
    const response = await set_payment(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    console.log('errr', err)
    Promise.reject(err)
    throw err
  }
})

///////////////////////////// Financ //////////////////////////
export const getFinancs = createAsyncThunk('financ/getFinancs', async (data: any) => {
  try {
    const response = await get_financs(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

const financSlice = createSlice({
  name: 'financ',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBanks.fulfilled, (state, action) => {
        if (action.payload) {
          state.banks = action.payload.data.data
        }
      })
      .addCase(getCheques.pending, (state, action) => {
        state.chequeTableLoading = true
      })
      .addCase(getCheques.fulfilled, (state, action) => {
        if (action.payload) {
          state.chequePagination = action.payload.data.pagination
          state.chequeTableLoading = false
          state.cheques = action.payload.data.data
        }
      })
      .addCase(setCheque.fulfilled, (state, action) => {
        if (action.payload) {
          state.chequeSetLoading = false
        }
      })
      .addCase(setCheque.rejected, (state, action) => {
        state.chequeSetLoading = false
        throw new Error('Have Error')
      })
      .addCase(updateCheque.pending, (state, action) => {
        state.chequeUpdatedeLoading = true
      })
      .addCase(updateCheque.fulfilled, (state, action: any) => {
        state.chequeUpdatedeLoading = false
      })
      .addCase(updateCheque.rejected, (state, action: any) => {
        state.chequeUpdatedeLoading = false
      })
      .addCase(getPayments.pending, (state, action) => {
        state.paymentTableLoading = true
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentPagination = action.payload.data.pagination
          state.paymentTableLoading = false
          state.payments = action.payload.data.data
        }
      })
      .addCase(setPayment.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentSetLoading = false
        }
      })
      .addCase(setPayment.rejected, (state, action) => {
        state.paymentSetLoading = false
        throw new Error('Have Error')
      })
      .addCase(getFinancs.pending, (state, action) => {
        state.financsTableLoading = true
      })
      .addCase(getFinancs.fulfilled, (state, action) => {
        if (action.payload) {
          state.financPagination = action.payload.data.pagination
          state.financsTableLoading = false
          state.financs = action.payload.data.data
        }
      })
  },
})

export const {handle_variables} = financSlice.actions

export const financState = (state: any) => state.financ

export default financSlice.reducer
